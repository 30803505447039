import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import YourOrder from "../containers/singleInformation/summary/your-order"
import {resetRegistration} from "../redux/registration/registrationAction"

const ConfirmationPaymentPage = () => {
    const dispatch = useDispatch()
    const { ticket, options } = useSelector(({registrationReducer}) => registrationReducer)
    
      useEffect(() => {
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    const getVAT = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    const getTotal = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    return (
        <Layout main={false}>
            <SEO title="Confirmation payment" />
            <Header/>
            <div className={"page"}>
                <h2 className={"page-title"}>Votre inscription à bien été enregistrée</h2>
  
            <p>Vous recevrez quelques jours avant l’événement un email avec le login et mot de passe de connexion à la plateforme du congrès.</p>
                                                                                          
            <p>Nous vous invitons dès à présent à consulter l’ensemble des informations pratiques de ce format inédit (plateaux TV, e-sessions, VOD, e-exposition…)</p>
             <a href="https://www.sforl.org/programme-scientifique/" className={"sr-btn-next btn btn-info mx-auto mb-4"} >congrès</a>
              <p>                                                                     
                <strong>Le service inscriptions du congrès des JTIC</strong>
            </p>
            </div>
        </Layout>
    )
}

export default ConfirmationPaymentPage
